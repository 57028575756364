import './NikolaLajic.css';

function Home() {
    return (
        <div className="about-container">
            <p className="about-content">
                Nikola is an artist currently focused on acting and design.
            </p>
            <p className="about-content">
                He graduated from the Faculty of Architecture at the University of Belgrade, after which he shifted his interests towards object design and acting. His work methodology emphasizes experimentation with materials and casting techniques, often resulting in unique forms and textures.
            </p>
            <p className="about-content">
                Nikola draws inspiration largely from nature; however, rather than replicating its forms, he explores the processes behind how these natural shapes originally occur.
            </p>
            <p className="about-content">
                In addition to his work in design, Nikola is actively involved in acting and is open to collaboration. Although the mediums differ, his interest in acting mirrors that in design: attention to detail and specificity.
            </p>
            <p className="about-content">
                What he finds captivating is the character’s nature and is intrigued by the challenge of conveying their unique traits to the audience. This approach not only makes acting enjoyable for him but also offers an engaging experience for viewers.
            </p>
        </div>
    );
}

export default Home;



// nav crno a cover sivo