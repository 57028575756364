import './Objects.css';
import { Link } from "react-router-dom";
const images = require.context('../images/objects', true);

function Objects() {
    const piecesList = [
        {
            id: 1,
            image: '1.png',
            description: {
                name: 'Cocktail Table',
                dimensions:'Dimensions: 98cm x 79cm x 42cm',
                material: 'Material: ceramics',
                status: 'Production ongoing',
            },
        }, {
            id: 2,
            image: '1.png',
            description: {
                name: 'Common Form no.1',
                dimensions:'Dimensions: 44cm x 20cm x 20cm',
                material: 'Material: polystyrene, clay',
                status: 'Available pieces',
            },
        }, {
            id: 3,
            image: '1.png',
            description: {
            name: 'Credenza',
                dimensions:'Dimensions: 123cm x 38cm x 43cm (78cm)',
                material: 'Material: glass',
                status: 'Production ongoing',
            },
        }, {
            id: 4,
            image: '1.png',
            description: {
                name: 'Dining Table',
                dimensions:'Dimensions: 120cm x 120cm x 76cm',
                material: 'Material: marble',
                status: 'Production ongoing',
            },
        }, {
            id: 5,
            image: '1.png',
            description: {
                name: 'Side Table Eccentric',
                dimensions:'Dimensions: 60cm x 60cm x 60cm',
                material: 'Material: walnut, ash, oak',
                status: 'Available pieces',
            },
        }, /*{
            id: 6,
            image: '1.png',
            description: {
                name: 'Coffee Table Inconcrete',
                dimensions:'Dimensions: 46cm x 42cm x 44cm',
                material: 'Material: concrete',
                status: 'Available pieces',
            },
        },*/ {
            id: 7,
            image: '1.png',
            description: {
                name: 'Side Table',
                dimensions:'Dimensions: 65cm x 40cm x 56cm',
                material: 'Material: ceramics',
                status: 'Production ongoing',
            },
        }, /*{
            id: 8,
            image: '1.png',
            description: {
                name: 'Side Table',
                dimensions:'Dimensions: 40cm x 30cm x 60cm',
                material: 'Material: ceramics',
                status: 'Production ongoing',
            },
        },*/
    ];
    return (
        <div className="pieces-container">
            {piecesList.map(piece => (
                <div className="pieces-item" style={{marginTop: "30px"}}>
                        <Link className="piece-container" key={piece.id} to={`/pieces/${piece.id}`}>
                            <img src={images(`./${piece.id}/${piece.image}`)} alt={piece.image} key={`${piece.id}-${piece.image}`}/>
                        </Link>
                </div>
            ))}
        </div>
    );
}

export default Objects;
