import Navigation from "./navigation";
import Footer from "./footer/Footer";
import { Outlet } from "react-router-dom";

function App() {
  return (
      <div>
          <Navigation/>
          <div id="detail">
              <Outlet />
          </div>
          <Footer/>
      </div>
  );
}

export default App;
