import './Acting.css';

function Objects() {
    return (
        <div className="acting-container">
            Coming soon...
        </div>
    );
}

export default Objects;
