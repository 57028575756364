import './style.css';
import { Link } from "react-router-dom";

function Navigation() {
    return (
        <div className="navigation-container">
                <Link className="navigation-button" to={`/`} style={{marginLeft: 0} }>
                    Nikola Lajic
                </Link>
                <Link className="navigation-button" to={`acting/`}>
                    Acting
                </Link>
                <Link className="navigation-button" to={`set-design/`}>
                    Set design
                </Link>
                <Link className="navigation-button" to={`objects/`}>
                    Objects
                </Link>
                <Link className="navigation-button" to={`info/`}>
                    Info
                </Link>
        </div>
    );
}

export default Navigation;