import './SetDesigns.css';
const images = require.context('../images/set-design', true);

function SetDesign() {
    const setDesignList = ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png'];
    return (
        <div className="designs-container">
            {setDesignList.map(design => (
                <div className="design-item" style={{marginTop: "30px"}}>
                    <div className="piece-container" key={design}>
                        <img src={images(`./1/${design}`)} alt={design} key={`${design}`}/>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default SetDesign;